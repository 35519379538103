<template>
  <div class="alarm-history-item">
    <span class="title">报警记录</span>

    <div
      ref="scrollContainer"
      class="alarm-list"
      @mousedown="startDrag"
      @mousemove="drag"
      @mouseup="endDrag"
      @mouseleave="endDrag"
    >
      <div
        class="scroll-content"
        ref="scrollContent"
        :style="{ transform: `translateY(${scrollPosition}px)` }"
      >
        <div class="alarm-item" v-for="(item, index) in msgList" :key="index" @click="onSelectItem(item)">
          <div>{{ item.MemberName }}</div>
          <div>
            {{
              util.dateFormat(
                "YYYY-mm-dd HH:MM:SS",
                new Date(item.ArriveTime * 1000)
              )
            }}
          </div>
          <div>{{ item.StatusName }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Aep from "@/api/aep.js";

export default {
  // props: {
  //   msgId: {
  //     type: [String, Number],
  //     default: () => "",
  //   },
  // },

  data() {
    return {
      msgList: [],
      scrollPosition: 0, // 滚动位置
      dragging: false, // 是否正在拖动
      lastMousePosition: 0, // 上一次鼠标位置
      scrollInterval: null, // 自动滚动的定时器
      contentHeight: 0, // 内容的总高度
      containerHeight: 200, // 容器的高度，根据实际情况调整
    };
  },

  // watch: {
  //   msgId: {
  //     handler(newVal, oldVal) {
  //       this.getMessageSimpleList();
  //     },
  //     immediate: true,
  //   },
  // },

  mounted() {
    this.getMessageSimpleList();
    this.calculateDimensions(); // 计算内容和容器的尺寸
    this.startScrollInterval(); // 组件挂载后开始自动滚动
  },

  beforeDestroy() {
    this.clearScrollInterval(); // 组件销毁前清除定时器
  },

  methods: {
    // 获取报警记录
    async getMessageSimpleList() {
      try {
        let { data } = await Aep.getMessageSimpleList();
        this.msgList = data;
      } catch (err) {
        console.log(err);
      }
    },

    // 选中事件
    onSelectItem(event){
      this.$emit('select', event)
    },

    // 计算内容和容器的尺寸
    calculateDimensions() {
      this.$nextTick(() => {
        const content = this.$refs.scrollContent;
        const container = this.$refs.scrollContainer;
        this.contentHeight = content.scrollHeight;
        this.containerHeight = container.clientHeight;
      });
    },

    // 鼠标按下开始拖动事件
    startDrag(event) {
      this.dragging = true;
      this.lastMousePosition = event.clientY;
      this.clearScrollInterval(); // 清除自动滚动定时器
      document.addEventListener("mousemove", this.drag);
      document.addEventListener("mouseup", this.endDrag);
    },

    // 鼠标移动事件
    drag(event) {
      if (this.dragging) {
        const deltaY = event.clientY - this.lastMousePosition;
        this.scrollPosition += deltaY;
        this.lastMousePosition = event.clientY;
        this.limitScrollPosition(); // 限制滚动位置
      }
    },

    // 鼠标松开结束拖动事件
    endDrag() {
      this.dragging = false;
      document.removeEventListener("mousemove", this.drag);
      document.removeEventListener("mouseup", this.endDrag);
      this.startScrollInterval(); // 重新开始自动滚动
    },

    // 开始自动滚动定时器
    startScrollInterval() {
      this.clearScrollInterval(); // 清除已有的定时器
      this.scrollInterval = setInterval(() => {
        this.scrollPosition += -50; // 向上滚动(指鼠标向上移动，列表实际是向下滚动的，列表滚动是和鼠标相反的，鼠标向上移动y轴减小，列表向下滚动y轴增加)
        this.limitScrollPosition(); // 限制滚动位置
      }, 5000); // 每5秒滚动一次，速度可以根据需要调整
    },

    // 清除自动滚动定时器
    clearScrollInterval() {
      if (this.scrollInterval) {
        clearInterval(this.scrollInterval);
        this.scrollInterval = null;
      }
    },

    // 限制滚动位置
    limitScrollPosition() {
      // 限制滚动位置，防止超出内容的底部
      const maxScrollPosition = this.contentHeight - this.containerHeight;
      // console.log(this.scrollPosition, this.contentHeight, maxScrollPosition);
      if (this.scrollPosition > 0) {
        this.scrollPosition = 0;
      }
      if (this.scrollPosition < 0 - maxScrollPosition) {
        this.scrollPosition = 0 - maxScrollPosition;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.alarm-history-item {
  .flex-col;
  width: 100%;
  height: 100%;
  font-size: 15px;
  background-color: rgba(0, 0, 0, 0.3);

  .title {
    padding: 10px 0;
    color: #ccc;
    text-align: center;
  }

  .alarm-list {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: auto;

    .scroll-content {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      transition: transform 0.3s ease;
    }

    .alarm-item {
      .flex-row;
      justify-content: space-between;
      width: 100%;
      padding: 10px;
      margin-bottom: 10px;
      box-sizing: border-box;
      background-color: rgba(0, 0, 0, 0);
      cursor: pointer;
      transition: all 0.2s linear;

      user-select: none; /* 禁止选中文字拖动 */
      -webkit-user-select: none; /* Safari */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* IE10+/Edge */

      &:hover {
        background-color: rgba(0, 255, 255, 0.3);
      }
    }
  }
}
::-webkit-scrollbar {
  display: none;
}

// 取消选中文字时的背景色
::selection {
  background: unset;
  color: unset;
}
</style>
