<template>
  <div class="alarm">
    <div class="content-box">
      <div class="alarm-history">
        <AlarmHistoryItem
          ref="alarmHistoryItem"
          :msgId="msgId"
          @select="onSelectItem"
        ></AlarmHistoryItem>
      </div>

      <div class="alarm-basic">
        <div v-if="showBasic" class="basic-box">
          <i class="el-icon-close" @click="showBasic = false"></i>

          <div class="main-basic">
            <div class="image">
              <el-image
                v-if="alarmInfo.MemberHead"
                :src="alarmInfo.MemberHead"
              ></el-image>
              <div v-else class="head">
                <div>{{ alarmInfo.MemberName.slice(0, 1) }}</div>
                <div class="tips">暂无头像</div>
              </div>
            </div>

            <div class="info">
              <div class="name">名称：{{ alarmInfo.MemberName }}</div>
              <div class="sex">
                性别：{{ alarmInfo.MemberSex ? "女" : "男" }}
              </div>
              <div class="phone">联系电话：{{ alarmInfo.MemberPhone }}</div>
              <div class="time">
                报警时间：{{
                  util.dateFormat(
                    "YYYY-mm-dd HH:MM:SS",
                    new Date(alarmInfo.ArriveTime * 1000)
                  )
                }}
              </div>
              <!-- <div class="type">所属：养老集团</div> -->
            </div>
          </div>

          <!-- <div class="address">地址：哥谭市</div> -->

          <div class="kin">
            <!-- <div class="">儿子：1290412412333</div> -->
          </div>

          <div class="handle">
            <el-button
              v-show="alarmInfo.Status != 2"
              type="primary"
              size="mini"
              @click="onShowHandle"
              >{{ alarmInfo.Status ? "填写处理结果" : "前去处理" }}</el-button
            >
          </div>
        </div>
      </div>

      <div class="health-box">
        <div class="info1">
          <span>正常血压：</span>
          <span>收缩压 < 120mmHg</span>
          <span>舒张压 < 80mmHg</span>
          <span>正常高值血压：</span>
          <span>收缩压在120~139mmHg</span>
          <span>舒张压在80~89mmHg之间</span>
        </div>
        <div class="info2">
          正常人空腹血糖正常值3.9~6.1mmol/L之间。餐后1小时血糖在6.7~9.4mmol/L之间，最多也不超过11.1mmo/L。餐后2小时血糖正常值通常小于7.8mmol/L，随机血糖一般应小于11.1mmol/L
        </div>
        <div class="info3">
          尿酸数值范围为男性正常值：150～416μmol/L（微摩尔/升），女性正常值：89～357μmol/L（微摩尔/升）
        </div>
        <div class="info4">指甲式血氧仪动脉血氧正常值一般是到95%~98%之间</div>
        <div class="info5">
          血脂包括总胆固醇、甘油三酯等。甘油三酯的正常测值在0.56~1.7mmol/L，而低密度脂蛋白胆固醇的正常范围则不高于3.12mmol/L，总胆固醇的正常测值在2.8~5.17mmol/L
        </div>
      </div>
    </div>

    <div class="background"></div>
    <div v-show="showWarning" class="warning-back"></div>

    <DfHandlePressing
      ref="dfHandlePressing"
      @success="updateMsg"
    ></DfHandlePressing>
  </div>
</template>

<script>
import Aep from "@/api/aep.js";

import AlarmHistoryItem from "./components/alarm-history-item.vue";

import imgPath from "@/assets/img/empty.jpg";

const signalR = require("@microsoft/signalr");
let img = imgPath;
export default {
  components: { AlarmHistoryItem },

  data() {
    return {
      msgId: "",
      connection: "",
      t_rtcid: "",
      aepFeature: 0,
      imgUrl: img,
      alarmInfo: {
        MemberName: "",
      },
      showWarning: false,
      showBasic: false,
    };
  },

  created() {
    let { rtcid } = JSON.parse(window.sessionStorage.getItem("accountInfo"));
    let aepFeature = this.storage.getStorage("systemData").feature.AepFeature;
    this.t_rtcid = rtcid;
    this.aepFeature = aepFeature;
    this.msgId = this.$route.query.msg_id || "";

    if (!this.connection && aepFeature) {
      // setTimeout(() => {
      //   this.receiveInformationPush({ AlertText: "收到一条紧急报警消息" });
      // }, 3000);
      this.connectionWebSocket();
    }
  },

  mounted() {
    if (this.msgId) {
      this.showBasic = true;
      this.getMessageViewDetail();
    }
  },

  beforeDestroy() {
    this.connection.onclose();
  },

  methods: {
    // 获取消息详情
    async getMessageViewDetail() {
      try {
        let { data } = await Aep.getMessageViewDetail({
          msg_id: this.msgId,
        });
        this.alarmInfo = data;
        this.showBasic = true;
      } catch (err) {
        console.log(err);
      }
    },

    // 更新消息状态为处理中
    async acceptMessage(msgId) {
      try {
        let { errcode } = await Aep.acceptMessage({
          msg_id: msgId,
        });
        if (errcode == 0) {
          this.$message.success("状态改变成功");
          this.updateMsg();
        }
      } catch (err) {
        console.log(err);
      }
    },

    // 报警记录选中事件
    onSelectItem(event) {
      this.msgId = event.MsgId;
      this.getMessageViewDetail();
    },

    // 更新消息数据
    updateMsg() {
      this.$refs.alarmHistoryItem.getMessageSimpleList();
      this.getMessageViewDetail();
    },

    // 显示处理结果对话框
    onShowHandle() {
      let { alarmInfo } = this;
      if (alarmInfo.Status == 0) {
        this.$confirm("是否前去处理。", "信息", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        }).then(() => {
          this.acceptMessage(alarmInfo.MsgId);
        });
      } else {
        this.$refs.dfHandlePressing.onShowDialog(alarmInfo.MsgId);
      }
    },

    /**
     * signalr通讯
     */
    async connectionWebSocket() {
      // let api = "https://api.difengsoft.com/signalr-pro/hubs/device?rtcid=";
      let api = "http://signalr-dev.difengsoft.com/hubs/device?rtcid=";

      this.connection = await new signalR.HubConnectionBuilder()
        .withUrl(api + this.t_rtcid, {})
        .configureLogging(signalR.LogLevel.Error)
        .build();

      this.connection.on("ReceiveUnifiedMessage", (msgInfo) => {
        // console.log("我是水单列表更新", msgInfo);
        this.receiveInformationPush(msgInfo);
        // this.$ownerInstance.callMethod('receiveInformationPush', msgInfo);
      });

      // 自动重连成功后的处理
      this.connection.onreconnected((connectionId) => {});
      // 计时同信启动成功
      // if (this.connection.state !== signalR.HubConnectionState.Connected) {

      // }
      // 生命周期
      this.connection.onreconnecting((error) => {});
      // (默认4次重连)，任何一次只要回调成功，调用
      this.connection.onreconnected((connectionId) => {});

      try {
        await this.connection.start();

        this.connection.onclose(async (error) => {
          this.connection = null;
          if (!this.connection && this.aepFeature) {
            await this.connectionWebSocket(); // 重连
          }
        });
      } catch (err) {
        console.log(err);
        setTimeout(() => {
          this.connection = null;
          if (!this.connection && this.aepFeature) this.connectionWebSocket();
        }, 1000);
      }
    },

    // 紧急报警推送
    async receiveInformationPush(event) {
      // console.log(event, "ddd");
      this.showWarning = true;
      setTimeout(() => {
        this.showWarning = false;
      }, 8000);
      // window.sessionStorage.setItem("receiveInfoList");
      try {
        this.$refs.alarmHistoryItem.getMessageSimpleList();
        await Aep.ensureReceiveMessage({
          msg_id: event.MsgId,
        });
      } catch (err) {
        console.log(err);
      }

      this.$confirm(event.AlertText, "紧急报警提示", {
        type: "warning",
        closeOnClickModal: false,
        closeOnPressEscape: false,
        confirmButtonText: "立即处理",
        cancelButtonText: "关闭",
      })
        .then(() => {
          this.onSelectItem(event);
        })
        .finally(() => {
          this.showWarning = false;
        });
      // this.$message.error('收到一条紧急报警消息')
    },
  },
};
</script>

<style lang="less" scoped>
.alarm {
  position: relative;
  width: 100vw;
  height: 100vh;

  .content-box {
    .flex-row;
    width: 100%;
    height: 100%;
    padding: 20px 10px;
    color: white;
    box-sizing: border-box;

    > div {
      flex: 1;
      height: 100%;
      margin: 0 10px;
      box-sizing: border-box;
    }

    .alarm-basic {
      .flex-row;
      justify-content: center;
      flex: 3;

      .basic-box {
        position: relative;
        width: 420px;
        min-height: 240px;
        color: black;
        background-color: white;
        padding: 20px;
        box-sizing: border-box;
        border-radius: 10px;

        i {
          position: absolute;
          top: 5px;
          right: 5px;
          padding: 5px;
          font-size: 16px;
          color: #bbb;
          font-weight: bold;
          cursor: pointer;
        }

        > div {
          margin-bottom: 20px;
          font-size: 15px;
        }

        .main-basic {
          .flex-row;

          .image {
            width: 130px;
            height: 140px;
            margin-right: 10px;

            .el-image {
              width: 100%;
              height: 100%;
            }

            .head {
              .flex-col;
              position: relative;
              height: 100%;
              width: 100%;
              background-color: rgb(200, 197, 200);
              border-radius: 10px;

              > div:first-child {
                .flex-row;
                justify-content: center;
                flex: 1;
                font-size: 40px;
                font-weight: bold;
              }

              .tips {
                .flex-row;
                justify-content: center;
                width: calc(100% - 2px);
                height: 30px;
                color: #e6a23c;
                border-radius: 10px 10px 0 0;
                background: #fdf6ec;
                border: 1px solid #faecd8;
              }
            }
          }

          .info {
            line-height: 26px;

            > div {
              span:last-child {
                margin-left: 10px;
              }
            }
          }
        }

        .handle {
          margin: 0;
          .el-button {
            width: 100% !important;
            font-size: 14px;
          }
        }
      }
    }

    .health-box {
      height: 100%;
      padding: 20px;
      background-color: rgba(0, 0, 0, 0.3);
      overflow-y: auto;

      > div {
        .flex-col;
        align-items: unset;
        margin-bottom: 20px;
        font-size: 16px;
        line-height: 30px;
      }
    }
  }

  .background {
    position: absolute;
    top: 0;
    left: 0;
    background-image: url("../../../assets/img/pressing-back2.jpg");
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    z-index: -1;
  }

  .warning-back {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    box-shadow: inset 0 0 1000px 360px rgba(255, 0, 0, 0.5);
    z-index: 20;
    animation: warning 8s infinite;
  }

  @keyframes warning {
    0%,
    20%,
    36%,
    48%,
    56%,
    64%,
    68%,
    72%,
    76%,
    80%,
    84%,
    88%,
    92%,
    96%,
    100% {
      box-shadow: inset 0 0 1000px 360px rgba(0, 0, 255, 0.2);
    }
    10%,
    28%,
    42%,
    52%,
    60%,
    66%,
    70%,
    74%,
    78%,
    82%,
    86%,
    90%,
    94%,
    98% {
      box-shadow: inset 0 0 1000px 360px rgba(255, 0, 0, 0.5);
    }
  }
}
</style>
